import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, Card, Form, FloatingLabel, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useLocation } from "react-router-dom"
import axios from "axios";
import { toast } from 'react-toastify';
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import Pagination from '../layout/Pagination';
let PageSize = 10;
const Bookings = () => {

    const location = useLocation();
    let periodfromdash = (location.state !== undefined && location.state !== null) ? location.state.period : ""
    let statusfromdash = (location.state !== undefined && location.state !== null) ? location.state.status : "";
    let sdate = (location.state !== undefined && location.state !== null) ? location.state.dates : "";
    let edate=sdate;

    const [BookingsData, setBookingsData] = useState([]);

    const [Status, setStatus] = useState("");
    const [Service, SetService] = useState("");
    const [BookedBy, setBookedBy] = useState("");
    const [ServiceProvider, setServiceProvider] = useState("");

    const [startDate, setstartDate] = useState("");
    const [endDate, setendDate] = useState("");

    const [Period, setPeriod] = useState("");


    const [totalUser, setTotalUser] = useState("");

    const [isSending, setIsSending] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [isLoaded, SetisLoaded] = useState(true)



    useEffect(() => {

        if (isSending) {
            //send the request
            setIsSending(false);
        }
        setPeriod(periodfromdash);
        var p = Period;
        var s = Status;
        var sd="";
        var ed="";

        if (periodfromdash !== null && periodfromdash !== undefined) {
            console.log('Period is  ', periodfromdash)
            if (periodfromdash === 'ThisYear')
                p = "periodfromdash"
            if (periodfromdash === 'ThisMonth')
                p = "ThisMonth"
            if (periodfromdash === 'ThisWeek')
                p = "ThisWeek"
            if (periodfromdash === 'PreviousYear')
                p = "PreviousYear"
            if (periodfromdash === 'PreviousMonth')
                p = "PreviousMonth"
            if (periodfromdash === 'PreviousWeek')
                p = "PreviousWeek"


        }
        if (statusfromdash !== null && statusfromdash !== undefined) {
            if (statusfromdash === "Booking")
                s = ""
            if (statusfromdash === "Completed")
                s = "Completed" 
                if (statusfromdash === "Received")
                s = "Accepted"
            if (statusfromdash === "Confirmed")
                s = "Completed" 
                if (statusfromdash === "Rejected")
                s = "Rejected"                    


        }
        if (sdate !== null && sdate !== undefined) {
           sd=sdate;
           ed=sdate;

        }

        console.log(s,p,"from dashboard")

        
        setStatus(s)
        searchData(s, Service, BookedBy, ServiceProvider,p)
        console.log(Status);

    }, [isSending]);

   function ResetAll()
    {
        searchData(Status, Service, BookedBy, ServiceProvider,"")
    }

    const searchData = async (Status, Service, BookedBy, ServiceProvider,Period) => {
        SetisLoaded(false)
        var result = await APIHandler.get(`bookings/admin?service=${Service}&status=${Status}&ServiceProvider=${ServiceProvider}&booked_by=${BookedBy}&period=${Period}`)
        console.log("Booking ", result)
        setBookingsData(result.data);
        setTotalUser(result.message)
        SetisLoaded(true)
    }

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return BookingsData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, BookingsData]);




    return (<>
        <Layout />
        <div className="content-wrapper my_wrapper">
            <div className="content-header">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Bookings</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active">Bookings</li>
                        </ol>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="row">
                    <div className="col-12">
                        <Row>
                            <Form.Group as={Col} controlId="tele">
                                <Form.Label>Status</Form.Label><br />
                                <select className="form-control" name="status" value={Status} onChange={(e) => setStatus(e.target.value)}>
                                    <option value="">All</option>
                                    <option value="Accepted">Accepted</option>
                                    <option value="Completed">Completed</option>
                                    <option value="NotCompleted">Not Completed</option>
                                    <option value="Rejected">Rejected</option>
                                    <option value="Cancelled">Cancelled</option>
                                    <option value="Rescheduled">Rescheduled</option>
                                </select>
                            </Form.Group>

                            <Form.Group as={Col} controlId="tele">
                                <Form.Label>Service</Form.Label><br />
                                <select className="form-control" name="Service" value={Service} onChange={(e) => SetService(e.target.value)}>
                                    <option value="">All Service</option>
                                    <option value="Doctor">Teleconsultation</option>
                                    <option value="Nurse">Home Care Nurse</option>
                                    <option value="Ambulance">Ambulances Providers</option>
                                </select>
                            </Form.Group>
                            <Form.Group as={Col} controlId="tele">
                                <Form.Label>Booked By</Form.Label>
                                <Form.Control name="search" value={BookedBy} onChange={(e) => setBookedBy(e.target.value)} placeholder="Name, Location, Mobile, Email" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="tele">
                                <Form.Label>Services Provider</Form.Label>
                                <Form.Control name="search" value={ServiceProvider} onChange={(e) => setServiceProvider(e.target.value)} placeholder="Name, Location, Mobile, Email" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="tele">
                                <Form.Label>&nbsp;</Form.Label><br />
                                <Button type="submit" className="btn btn-primary" value="" onClick={(e) => setIsSending(true)} > <i className='fa fa-search'></i>Search</Button>
                                &nbsp;<Button type="submit" className="btn btn-primary" value="" onClick={ResetAll} > Reset</Button>
                            </Form.Group>
                            

                        </Row>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-primary mt-4">
                            <div className="card-header">
                                <h3 className="card-title">{totalUser}</h3>
                            </div>
                            <div className="card-body">
                                <table className="table table-striped  table-bordered" >
                                    <thead>
                                        <tr>
                                            <th>
                                                Booking ID
                                            </th>
                                            <th>
                                                Created On
                                            </th>
                                            <th>
                                                Service
                                            </th>
                                            <th>
                                                Service Provider
                                            </th>
                                            <th>
                                                User
                                            </th>
                                            <th>
                                                Appointment on/ Scheduled On
                                            </th>
                                            <th>
                                                Staus
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                    { isLoaded ?(
                                        (currentTableData!==null && currentTableData.length>0)?
                                        currentTableData.map(obj => (
                                            <tr>

                                                <td> <Link to='/booking-detail' state={{ bookingid: obj.booking_id }}>
                                                    {obj.booking_id}
                                                </Link> </td>
                                                <td>{moment(obj.created_at).format("YYYY/MM/DD hh:mm A")}  </td>
                                                <td>{obj.service}</td>
                                                <td>{obj.service_Provider} </td>
                                                <td>{obj.User}</td>

                                                <td>
                                                    {moment(obj.appointment_date).format("YYYY/MM/DD hh:mm A")}
                                                </td>
                                                <td>{obj.status} </td>

                                            </tr>
                                        )):<tr><td colSpan={7}>No data found</td></tr>) : <tr><td colSpan={7}>  <div className="loader"></div></td></tr>}
                                    </tbody>
                                </table>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={BookingsData.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />

                            </div>

                        </div>
                    </div>
                </div>



            </section>
        </div>

    </>);
}
export default Bookings;