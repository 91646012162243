import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, FloatingLabel, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import RichTextEditor from 'react-rte';
import axios from "axios";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom"
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import { toast } from 'react-toastify';
import moment from 'moment';
import Eprecriptiondownload from "../Bookings/downloadeprescription"
import CircularProgress from '@mui/material/CircularProgress';
const baseUrl = process.env.REACT_APP_API_URL

const Masters = () => {
    const location = useLocation()

    const [tab, setTab] = useState('Booking Summary')
    const token = localStorage.getItem("token")
    const [isLoaded, setIsLoaded] = useState(true)
    const [filepath, setfilepath] = useState("resources/specialization/orthopedician.png")
   
    const [specializationdList, SetspecializationList] = useState("")
    const [specializationid, Setspecializationid] = useState("")

    const [homecareList, SethomecareList] = useState("")
    const [homecareid, Sethomecareid] = useState("")
    const [homeservicetype, Sethomeservicetype] = useState("")
    const [servicename, Setservicename] = useState("")
    const [servicetype, Setservicetype] = useState("")
 

    const [diaseseList, SetdiaseseList] = useState("")
    const [diaseseid, Setdiaseseid] = useState("")

    const [diseasetype, Setdiseasetype] = useState("")
    const [price,Setprice]= useState("")
    const [title, setTitle] = useState("")
    const [shortName, SetShortname] = useState("")
    const [description, Setdescription] = useState("")
    // const [filepath, setfilepath] = useState("")

    useEffect(() => {

        Getspecialization()
        GetDiasese()
        GetHomecare()


    }, [])

   
     //Specialization 
     const Getspecialization = async () => {
        var result = await APIHandler.get(`specialization`)
        if (result != undefined) {
            SetspecializationList(result.data);
        }
    }

    const AddSpecialization = async () => {
        setIsLoaded(false)

        var config = {
            headers: {
                'authorization': token,
            },
        };
        var data = {
            "title": title,
            "short_name": shortName,
            "description": description,
            "image_path": filepath
        }

        const result = await axios.post(`${baseUrl}specialization`, data, config)
        if (result != null) {
            Getspecialization()
            setIsLoaded(true)
            handleClose()
        }
    }

    const DeleteSpecialization = async (id) => {

        var config = {
            headers: {
                'authorization': token,
            },
        };
        

        const result = await axios.delete(`${baseUrl}specialization/${id}/masterspecialization`, config)
        if (result != null) {
            Getspecialization()
        }
    }
 
  //Diasese 
  const GetDiasese = async () => {
    var result = await APIHandler.get(`disease`)
    if (result != undefined) {
        SetdiaseseList(result.data);
    }
}

const AddDiasese = async () => {
    setIsLoaded(false)

    var config = {
        headers: {
            'authorization': token,
        },
    };
    var data = {

        "type":diseasetype,
        "title":title,
        "short_name":shortName,
        "description":description,
        "price":price,
        "image_path":filepath
       
    }

    const result = await axios.post(`${baseUrl}/disease?specialization_id=11`, data, config)
    if (result != null) {
        console.log("data for add",data)
        GetDiasese()
        setIsLoaded(true)
        handleClose()
    }
}

const DeleteDiasese = async (id) => {

    var config = {
        headers: {
            'authorization': token,
        },
    };
    

    const result = await axios.delete(`${baseUrl}/disease/${id}`, config)
    if (result != null) {
        GetDiasese()
    }
}

//Home care 
const GetHomecare = async () => {
    var result = await APIHandler.get(`masterHomecares`)
    if (result != undefined) {
        SethomecareList(result.data);
    }
}

const AddHomecare = async () => {
    setIsLoaded(false)

    var config = {
        headers: {
            'authorization': token,
        },
    };
    var data = {

        "type":servicetype,
        "name":servicename,        
        "short_description":description,
        "price":price,
        "banner_image":filepath
       
    }

    const result = await axios.post(`${baseUrl}/masterHomecares`, data, config)
    if (result != null) {
        console.log("data for add",data)
        GetHomecare()
        setIsLoaded(true)
        handleClose()
    }
}

const DeleteHomecare = async (id) => {

    var config = {
        headers: {
            'authorization': token,
        },
    };
    

    const result = await axios.delete(`${baseUrl}/masterHomecares/${id}`, config)
    if (result != null) {
        GetHomecare()
    }
}


 
 
 
    const handleClose = () => {
        window.$("#modalSpecializations").modal("hide")
        window.$("#modalDiseases").modal("hide")
        window.$("#modalHomeCare").modal("hide")
        window.$("#modalAmbulance").modal("hide")
    }
    const handleShow = () => {
        window.$("#modalSpecializations").modal("show")
        window.$("#modalDiseases").modal("show")
        window.$("#modalHomeCare").modal("show")
        window.$("#modalAmbulance").modal("show")
    }

    const UploadFile = async (e, type) => {
        setIsLoaded(false)

        var config = {
            headers: {
                'authorization': token,
            },
        };
        const selectedFile = e.target.files[0];
         const selectedFileName = e.target.files[0];
        const formData = new FormData();
        formData.append("files", selectedFile);

        const result = await axios.post(`${baseUrl}uploads?for=${type}`, formData, config)
        if (result != null && result!==undefined) {
            setfilepath(result.data.data.filePath)
            setIsLoaded(true)
        }
        //setIsLoaded(true)
    }


    return (
        <>
            <Layout />

            <div className="content-wrapper my_wrapper">
                <div className="content-header">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Masters </h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <Link to="/dashboard"><i className="bi bi-chevron-compact-left"></i> Home</Link>
                            </ol>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="row mt-2">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header d-flex p-0">
                                    <ul className="nav nav-pills p-2">
                                        <li className="nav-item"><a className="nav-link active" href="#tab_1" data-toggle="tab">Specializations </a></li>
                                        <li className="nav-item"><a className="nav-link" href="#tab_2" data-toggle="tab">Diseases</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#tab_3" data-toggle="tab">Home Care Services</a></li>
                                        {/* <li className="nav-item"><a className="nav-link" href="#tab_4" data-toggle="tab">Ambulance Types & Features </a></li> */}
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="tab_1">
                                            <div className='row user-action-box'>
                                                <div className="col-12">
                                                    <h5 className='bd_title'>Add New    <a onClick={() => { window.$("#modalSpecializations").modal("show") }} className='ml-1' title='Add Specializations'> <i className='fa fa-plus-circle'></i></a></h5>
                                                    <div className="">
                                                        <div className="">
                                                            <table className="table table-striped  table-bordered " >
                                                                <thead>
                                                                    <tr>
                                                                    <th>
                                                                             Specialization
                                                                        </th>

                                                                        <th>
                                                                            Name
                                                                        </th>
                                                                        <th>
                                                                            Description
                                                                        </th>                                                                        
                                                                        <th>
                                                                            image
                                                                        </th>
                                                                        <th>
                                                                            
                                                                        </th>


                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(specializationdList != null && specializationdList !== undefined && specializationdList.length > 0) &&
                                                                        specializationdList.map(obj => (
                                                                            <tr>
                                                                                <td>{obj.title}</td>
                                                                                <td>{obj.short_name}</td>
                                                                                <td>{obj.description}</td>
                                                                                <td><img src={obj.image_path}  style={{ width: "100px" }}/></td>
                                                                                <td><a onClick={() => DeleteSpecialization(obj.id)} style={{ cursor: "pointer",color:"red" }}> Delete</a></td>


                                                                            </tr>
                                                                        ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        <div className="tab-pane" id="tab_2">

                                            <div className='row user-action-box'>
                                                <div className="col-12">
                                                    <h5 className='bd_title'>Add New    <a onClick={() => { window.$("#modalDiseases").modal("show") }} className='ml-1' title='Add Diseases'> <i className='fa fa-plus-circle'></i></a></h5>
                                                    <div className="">
                                                        <div className="">
                                                            <table className="table table-striped  table-bordered " >
                                                                <thead>
                                                                    <tr>
                                                                    <th>
                                                                             Disease
                                                                        </th>

                                                                        <th>
                                                                            Type
                                                                        </th>
                                                                        <th>
                                                                            Description
                                                                        </th>                                                                        
                                                                        <th>
                                                                            image
                                                                        </th>
                                                                        <th>
                                                                            
                                                                        </th>


                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {(diaseseList != null && diaseseList !== undefined && diaseseList.length > 0) &&
                                                                    diaseseList.map(obj => (
                                                                            <tr>
                                                                                <td>{obj.title}</td>
                                                                                <td>{obj.type}</td>
                                                                                <td>{obj.description}</td>
                                                                                <td><img src={obj.image_path} style={{ width: "100px" }}/></td>
                                                                                <td><a onClick={() => DeleteDiasese(obj.id)} style={{ cursor: "pointer",color:"red" }}> Delete</a></td>


                                                                            </tr>
                                                                        ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        <div className="tab-pane" id="tab_3">

                                            <div className='row user-action-box'>
                                                <div className="col-12">
                                                    <h5 className='bd_title'>Add New    <a onClick={() => { window.$("#modalHomeCare").modal("show") }} className='ml-1' title='Add Home Care'> <i className='fa fa-plus-circle'></i></a></h5>
                                                    <div className="">
                                                        <div className="">
                                                            <table className="table table-striped  table-bordered " >
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            Service Name
                                                                        </th>

                                                                        <th>
                                                                            Description
                                                                        </th>
                                                                        <th>
                                                                            Service Type
                                                                        </th>
                                                                        <th>
                                                                            Price
                                                                        </th>
                                                                        <th>
                                                                            image
                                                                        </th>
                                                                        <th>
                                                                            
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {(homecareList != null && homecareList !== undefined && homecareList.length > 0) &&
                                                                    homecareList.map(obj => (
                                                                            <tr>
                                                                                <td>{obj.name}</td>
                                                                                <td>{obj.short_description}</td>
                                                                                <td>{obj.type}</td>
                                                                                <td>{obj.price}</td>
                                                                                <td><img src={obj.banner_image} style={{ width: "100px" }} /></td>
                                                                                <td><a onClick={() => DeleteHomecare(obj.id)} style={{ cursor: "pointer",color:"red" }}> Delete</a></td>


                                                                            </tr>
                                                                        ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                        {/* <div className="tab-pane" id="tab_4">

                                            <div className='row user-action-box'>
                                                <div className="col-12">
                                                    <h5 className='bd_title'>Add New    <a onClick={() => { window.$("#modalAmbulance").modal("show") }} className='ml-1' title='Add Ambulance'> <i className='fa fa-plus-circle'></i></a></h5>
                                                    <div className="">
                                                        <div className="">
                                                            <table className="table table-striped  table-bordered " >
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            Ambulance
                                                                        </th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div> */}

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>




                </section>
            </div>
            {/* <!-- Modal Specialization --> */}
            <div className="modal fade" id="modalSpecializations" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content" style={{ width: "800px" }}>
                        {/* <!-- Modal header --> */}
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalforprofile">
                                Specialization
                            </h5>
                            <button
                                type="button"
                                class="btn-close pull-right close" data-bs-dismiss="Close" aria-label="Close"
                                style={{ marginTop: "-24px" }}
                                onClick={() => { window.$("#modalSpecializations").modal("hide") }}
                            >&times;</button>

                        </div>
                        <div className="modal-body ">
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="Summary">
                                    <Form.Label>Specialization</Form.Label>
                                    <Form.Control name="Specialization" value={title} onChange={e=>setTitle(e.target.value)}/>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="Summary">
                                    <Form.Label>Short Name</Form.Label>
                                    <Form.Control name="shortname" value={shortName}  onChange={e=>SetShortname(e.target.value)}/>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="Summary">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control name="description" value={description} onChange={e=>Setdescription(e.target.value)}/>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="Summary">
                                    <Form.Label>Banner</Form.Label>
                                    <Form.Control name="description" type='file' onChange={e => UploadFile(e, "specialization")} />
                                </Form.Group>
                            </Row>



                        </div>


                        <div className="modal-footer d-flex justify-content-between">
                            <p></p>
                            <button type="button" className="btn btn-sm btn-primary-soft" disabled={!isLoaded} onClick={AddSpecialization}> {!isLoaded && <CircularProgress color="inherit" size={16} />} Save
                            </button>



                        </div>
                    </div>

                    {/* <!-- Form END --> */}
                </div>
            </div>
            {/* <!-- Modal Diseases --> */}
            <div className="modal fade" id="modalDiseases" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content" style={{ width: "800px" }}>
                        {/* <!-- Modal header --> */}
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalforprofile">
                                Diseases
                            </h5>
                            <button
                                type="button"
                                class="btn-close pull-right close" data-bs-dismiss="Close" aria-label="Close"
                                style={{ marginTop: "-24px" }}
                                onClick={() => { window.$("#modalDiseases").modal("hide") }}
                            >&times;</button>

                        </div>
                        <div className="modal-body ">
                        <Row className="mb-3">
                                <Form.Group as={Col} controlId="Summary">
                                    <Form.Label>Disease Type</Form.Label>
                                    <Form.Control name="diseasetype" value={diseasetype} onChange={e=>Setdiseasetype(e.target.value)}/>
                                </Form.Group>
                            </Row>
                        <Row className="mb-3">
                                <Form.Group as={Col} controlId="Summary">
                                    <Form.Label>Disease</Form.Label>
                                    <Form.Control name="Specialization" value={title} onChange={e=>setTitle(e.target.value)}/>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="Summary">
                                    <Form.Label>Short Name</Form.Label>
                                    <Form.Control name="shortname" value={shortName}  onChange={e=>SetShortname(e.target.value)}/>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="Summary">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control name="description" value={description} onChange={e=>Setdescription(e.target.value)}/>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="Summary">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control type="number" name="description" value={price} onChange={e=>Setprice(e.target.value)}/>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="Summary">
                                    <Form.Label>Banner</Form.Label>
                                    <Form.Control name="description" type='file' onChange={e => UploadFile(e, "disease")} />
                                </Form.Group>
                            </Row>


                        </div>


                        <div className="modal-footer d-flex justify-content-between">
                            <p></p>
                            <button type="button" className="btn btn-sm btn-primary-soft" disabled={!isLoaded} onClick={AddDiasese}> {!isLoaded && <CircularProgress color="inherit" size={16} />} Save
                            </button>



                        </div>
                    </div>

                    {/* <!-- Form END --> */}
                </div>
            </div>

            {/* <!-- Modal Home Care --> */}
            <div className="modal fade" id="modalHomeCare" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content" style={{ width: "800px" }}>
                        {/* <!-- Modal header --> */}
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalforprofile">
                                Home Care
                            </h5>
                            <button
                                type="button"
                                class="btn-close pull-right close" data-bs-dismiss="Close" aria-label="Close"
                                style={{ marginTop: "-24px" }}
                                onClick={() => { window.$("#modalHomeCare").modal("hide") }}
                            >&times;</button>

                        </div>
                        <div className="modal-body ">
                        <Row className="mb-3">
                                <Form.Group as={Col} controlId="Summary">
                                    <Form.Label>Service Type</Form.Label>
                                    <Form.Control name="servicetype" value={servicetype} onChange={e=>Setservicetype(e.target.value)}/>
                                </Form.Group>
                            </Row>
                        <Row className="mb-3">
                                <Form.Group as={Col} controlId="Summary">
                                    <Form.Label>Serveice Name</Form.Label>
                                    <Form.Control name="servicename" value={servicename} onChange={e=>Setservicename(e.target.value)}/>
                                </Form.Group>
                            </Row>
                           
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="Summary">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control name="description" value={description} onChange={e=>Setdescription(e.target.value)}/>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="Summary">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control type="number" name="description" value={price} onChange={e=>Setprice(e.target.value)}/>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="Summary">
                                    <Form.Label>Banner</Form.Label>
                                    <Form.Control name="description" type='file' onChange={e => UploadFile(e, "homecare")} />
                                </Form.Group>
                            </Row>


                        </div>


                        <div className="modal-footer d-flex justify-content-between">
                            <p></p>
                            <button type="button" className="btn btn-sm btn-primary-soft" disabled={!isLoaded} onClick={AddHomecare}> {!isLoaded && <CircularProgress color="inherit" size={16} />} Save
                            </button>



                        </div>
                    </div>

                    {/* <!-- Form END --> */}
                </div>
            </div>

            {/* <!-- Modal Ambulance  --> */}
            <div className="modal fade" id="modalAmbulance" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content" style={{ width: "800px" }}>
                        {/* <!-- Modal header --> */}
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalforprofile">
                                Ambulance
                            </h5>
                            <button
                                type="button"
                                class="btn-close pull-right close" data-bs-dismiss="Close" aria-label="Close"
                                style={{ marginTop: "-24px" }}
                                onClick={() => { window.$("#modalAmbulance").modal("hide") }}
                            >&times;</button>

                        </div>
                        <div className="modal-body ">
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="Summary">
                                    <Form.Label>Specialization</Form.Label>
                                    <Form.Control name="service" />
                                </Form.Group>
                            </Row>


                        </div>


                        <div className="modal-footer d-flex justify-content-between">
                            <p></p>
                            <button type="button" className="btn btn-sm btn-primary-soft"> Save
                            </button>



                        </div>
                    </div>

                    {/* <!-- Form END --> */}
                </div>
            </div>
        </>
    )
}
export default Masters
