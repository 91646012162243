import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, Card, Form, FloatingLabel, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom"
import axios from "axios";
import { toast } from 'react-toastify';
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import Pagination from '../layout/Pagination';
let PageSize = 10;

const Revenus = () => {

    const location = useLocation();
    let periodfromdash = (location.state !== undefined && location.state !== null) ? location.state.period : ""


    const [Period, setPeriod] = useState("ThisYear");
    const [Service, SetService] = useState("");
    const [ToDate, setToDate] = useState("");
    const [FromDate, setFromDate] = useState("");
    const [SearchBy, setSearchBy] = useState("");

    const [currentPage, setCurrentPage] = useState(1);

    const [totalRevenue, SettotalRevenue] = useState("");

    const [RevenueData, setRevenueData] = useState([]);
    const [TotalTransaction, setTotalTransaction] = useState("");
    const [isSending, setIsSending] = useState(false)
    const [isLoaded, SetisLoaded] = useState(true)

    useEffect(() => {
        if (isSending) {
            setIsSending(false);
        }
        var p = Period;
        if (periodfromdash !== null && periodfromdash !== undefined) {
            console.log('satus value ', periodfromdash)
            if (periodfromdash === 'ThisYear')
                p = "ThisYear"
            if (periodfromdash === 'ThisMonth')
                p = "ThisMonth"
            if (periodfromdash === 'ThisWeek')
                p = "ThisWeek"
            if (periodfromdash === 'PreviousYear')
                p = "PreviousYear"
            if (periodfromdash === 'PreviousMonth')
                p = "PreviousMonth"
            if (periodfromdash === 'PreviousWeek')
                p = "PreviousWeek"


        }
        setPeriod(p);
        searchData(p, Service, ToDate, FromDate, SearchBy)
        console.log(Period);

    }, [isSending]);



    const searchData = async (Period, Service, ToDate, FromDate, SearchBy) => {
       
        SetisLoaded(false)
        var result = await APIHandler.get(`search/revenue?role=${Service}&period=${Period}&search_key=${SearchBy}&from_date=${FromDate}&to_date=${ToDate}`)

        console.log("Revenue ", result)
        setRevenueData(result.data.transactions);
        console.log("Revenue ", result.data.transactions);
        setTotalTransaction(result.data.total_transactions)
        SettotalRevenue(result.data.total_revenue)
        SetisLoaded(true)
    }

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return RevenueData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, RevenueData]);


    return (<>
        <Layout />

        <div className="content-wrapper my_wrapper">
            <div className="content-header">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Revenue</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active">Revenue</li>
                        </ol>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="row">
                    <div className="col-lg-2">
                        <label>Service</label>
                        <select className="form-control" name="Service" value={Service} onChange={(e) => SetService(e.target.value)}>
                            <option value="">All Services</option>
                            <option value="Doctor">Teleconsultation</option>
                            <option value="Nurse">Home Care Nurse</option>
                            <option value="Ambulance">Ambulances Providers</option>
                        </select>
                    </div>
                    <div className="col-lg-2">
                        <label>Period</label>
                        <select className="form-control" name="Period" value={Period} onChange={(e) => setPeriod(e.target.value)}>
                            <option value="">All</option>
                            <option value="ThisWeek">This Week</option>
                            <option value="ThisMonth">This Month</option>
                            <option value="ThisYear">This Year</option>
                            <option value="PreviousWeek">Last Week</option>
                            <option value="PreviousMonth">Last Month</option>
                            <option value="PreviousYear">Last Year</option>
                            <option value="Custom">Custom</option>
                        </select>
                    </div>
                    {Period === "Custom" &&
                        <>
                            <div className="col-lg-2">
                                <label>From Date</label>
                                <input type="date" className="form-control" placeholder="From Date" value={FromDate} onChange={(e) => setFromDate(e.target.value)} />
                            </div>
                            <div className="col-lg-2">
                                <label>To Date</label>
                                <input type="date" className="form-control" placeholder="To Date" value={ToDate} onChange={(e) => setToDate(e.target.value)} />
                            </div>

                        </>
                    }


                    <div className="col-lg-2">
                        <label>Search</label>
                        <input type="text" className="form-control" placeholder="Search" value={SearchBy} onChange={(e) => setSearchBy(e.target.value)} />
                    </div>
                    <div className="col-lg-2">
                        <label >Action</label>
                        <div>
                            <button className="btn btn-primary" onClick={(e) => setIsSending(true)} ><i className="fa fa-search"></i> Search </button>
                            <button className="btn btn-primary ml-3"> <i className="fa fa-print"></i> </button>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-primary mt-4">
                            <div className="revenue_card_header">
                                <h3>{TotalTransaction} Transaction(s) Found</h3>

                                <h3> Total Revenue: Rs. {totalRevenue} </h3>
                            </div>
                            <div className="card-body">
                                <table className="table table-striped  table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Booking ID</th>
                                            <th>Created On</th>
                                            <th>Service</th>
                                            <th>Booked By </th>
                                            <th>Amount</th>
                                            <th>Payment Mode</th>
                                            <th>Transaction ID</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                    {isLoaded ?(
                                        (currentTableData!==null && currentTableData.length>0)?
                                        currentTableData.map(obj => (
                                            <tr>
                                                <td> <Link to='/booking-detail' state={{ bookingid: obj.booking_id }}>
                                                    {obj.booking_id}
                                                </Link> </td>
                                                <td>
                                                    {moment(obj.created_on).format("YYYY/MM/DD hh:mm A")}
                                                </td>

                                                <td>{obj.service}</td>
                                                <td>{obj.booked_by}</td>
                                                <td>{obj.amount}</td>
                                                <td>{obj.payment_mode}</td>
                                                <td>{obj.transaction_id}</td>


                                            </tr>
                                        )):<tr><td colSpan={7}>No data found</td></tr>) : <tr><td colSpan={7}>  <div className="loader"></div></td></tr>}
                                    </tbody>
                                </table>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={RevenueData.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </div>





    </>);
}
export default Revenus;