import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import APIHandler from '../helper/APIHandler'
import OnboardUser from '../helper/OnboardUser'
export default function Login() {
    const apifunction = new OnboardUser();
    const navigate = useNavigate()
    const [mobileOrEmail, setMobileOrEmail] = useState("")
    const [password, setPassword] = useState("")
    const [message, setMessage] = useState("")
    const [adminPass, setadminPass] = useState("")
      
    
    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();
        const data = {
            mobile: mobileOrEmail,
            password: password
        }      
       console.log("data fron start",data)
        apifunction.loginByPassword(data).then(result => {
            console.log(result)
            if (result !== undefined) {
                if (result.success === true) {
                    
                    let tokenUser = result.data.token
                   localStorage.setItem("token",tokenUser)                 
                   navigate('/dashboard')
                }
            }
        })
    };

    return (
        <div className="Auth-form-container">
            <form className="Auth-form" onSubmit={handleSubmit}>
                <div className="Auth-form-content">
                    <h3 className="Auth-form-title">Admin Login</h3>
                    <div className="form-group mt-3">
                        <label>Email address</label>
                        <input
                            type="text"
                            className="form-control mt-1"
                            placeholder="Enter email" value={mobileOrEmail} maxLength="255" onChange={(e) => setMobileOrEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label>Password</label>
                        <input
                            type="password"
                            className="form-control mt-1"
                            placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="d-grid gap-2 mt-3">
                        <button type="submit" className="btn btn-primary">
                            Login
                        </button>
                    </div>
                    <p className="forgot-password text-right mt-2">
                        {message}
                    </p>
                </div>
            </form>
        </div>
    )
}
