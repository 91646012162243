import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
const Layout=()=>{
    return(
    
    <>
  <Header />
  <Sidebar />
 
  




    </>);
}
export default Layout;