import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from "react-router-dom"
import { Row, Col, Card, Form, FloatingLabel, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify';
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import Pagination from '../layout/Pagination';
import { Loader, Placeholder } from 'rsuite';
let PageSize = 10;

const Users = () => {

    const location = useLocation();
    let rolefromdash = (location.state !== undefined && location.state !== null) ? location.state.role : ""
    let statusfromdash = (location.state !== undefined && location.state !== null) ? location.state.status : "";



    const [userData, setUserData] = useState([]);
    const [userrole, SetUserRole] = useState("");
    const [searchkey, setSearchkey] = useState("");
    const [userstatus, setUserStatus] = useState("");
    const [totalUser, setTotalUser] = useState("");

    const [isSending, setIsSending] = useState(false)
    const [isLoaded, SetisLoaded] = useState(true)


    //paging
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {

        if (isSending) {
            //send the request
            setIsSending(false);

        }
        var r = userrole;
        var s = userstatus

        if (statusfromdash !== null && statusfromdash !== undefined) {
            console.log('satus value ', statusfromdash)
            if (statusfromdash === "true")
                s = "1"
            if (statusfromdash === "false")
                s = "2"


        }
        if (rolefromdash !== null && rolefromdash !== undefined) {
            if (rolefromdash === "Doctor")
                r = "Doctor"
            if (rolefromdash === "Nurse")
                r = "Nurse"
            if (rolefromdash === "Ambulance")
                r = "Ambulance"
            if (rolefromdash === "User")
                r = "User"


        }
        SetUserRole(r);
        setUserStatus(s);
        console.log(r, s, "-----------------");
        searchData(r, searchkey, s)


    }, [isSending]);

    const searchData = async (userrole, searchkey, userstatus) => {
        SetisLoaded(false)

        var result = await APIHandler.get(`search/users?role=${userrole}&search_key=${searchkey}&status=${userstatus}`)
        console.log(result)

        setTotalUser(result.message);
        setUserData(result.data);
        SetisLoaded(true)
    }

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return userData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, userData]);




    return (<>
        <Layout />
        <div className="content-wrapper my_wrapper">
            <div className="content-header">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">User</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active">Users</li>
                        </ol>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="row">
                    <div className="col-12">
                        <Row>
                            <Form.Group as={Col} controlId="tele">
                                <Form.Label>Status</Form.Label><br />
                                <select className="form-control" name="status" value={userstatus} onChange={(e) => setUserStatus(e.target.value)}>
                                    <option value="">All</option>
                                    <option value="0">Pending</option>
                                    <option value="1">Active</option>
                                    <option value="0">Not Active</option>
                                </select>
                            </Form.Group>

                            <Form.Group as={Col} controlId="tele">
                                <Form.Label>Role</Form.Label><br />
                                <select className="form-control" name="uesrrole" value={userrole} onChange={(e) => SetUserRole(e.target.value)}>
                                    <option value="">All User</option>
                                    <option value="AllServiceProviders">All Service Providers</option>
                                    <option value="AllPatients">All Patients</option>
                                    <option value="Doctor">Doctors</option>
                                    <option value="Nurse">Home Care Nurse</option>
                                    <option value="Ambulance">Ambulances Providers</option>
                                </select>
                            </Form.Group>
                            <Form.Group as={Col} controlId="tele">
                                <Form.Label>Search</Form.Label>
                                <Form.Control name="search" value={searchkey} onChange={(e) => setSearchkey(e.target.value)} placeholder="Name, Location, Mobile, Email" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="tele">
                                <Form.Label>&nbsp;</Form.Label><br />
                                <Button type="submit" className="btn-primary" value="" onClick={(e) => setIsSending(true)} > <i className="fa fa-search"></i> Search</Button>
                            </Form.Group>

                        </Row>
                    </div>

                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-primary mt-4">
                            <div className="card-header">
                                <h3 className="card-title">{totalUser}</h3>
                            </div>
                            <div className="card-body">
                                <table className="table table-striped  table-bordered " >
                                    <thead>
                                        <tr>
                                            <th>
                                                Name
                                            </th>
                                            <th>
                                                Role
                                            </th>
                                            <th>
                                                Mobile
                                            </th>
                                            <th>
                                                Email
                                            </th>
                                            <th>
                                                City
                                            </th>
                                            <th>
                                                Join on
                                            </th>
                                            <th>
                                                Staus
                                            </th>
                                            <th>Profile</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            isLoaded ?(
                                                (currentTableData !== null && currentTableData.length > 0) ?
                                                currentTableData.map(obj => (
                                                    <tr>
                                                        <td>{obj.name}</td>
                                                        <td>{obj.role}</td>
                                                        <td>{obj.mobile}</td>
                                                        <td>{obj.email}</td>
                                                        <td>{obj.city} </td>
                                                        <td>
                                                            {moment(obj.created_at).format("YYYY/MM/DD")}
                                                        </td>
                                                        <td>{obj.status} </td>
                                                        <td> <Link to='/profile' state={{ userid: obj.id }}>
                                                            View
                                                        </Link> </td>
                                                    </tr>
                                                )):<tr><td colSpan={8}>No data found</td></tr>) : <tr><td colSpan={8}>  <div className="loader"></div></td></tr>}

                                    </tbody>
                                </table>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={userData.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </div>

    </>);
}
export default Users;