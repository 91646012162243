import React, { Component } from "react";
import Chart from "react-apexcharts";
import { useState } from "react";



const DonutChart=(props)=>{
    // const [state,setstate] = useState(
    //     {
    //         series: [44, 55, 13, 33],
    //         labels: ['Apple', 'Mango', 'Orange', 'Watermelon'],
    //         options: {
    //           chart: {
    //             width: 380,
    //             height:300,
    //             type: 'donut',
                
    //           },
             
    //           legend: {
    //             position: 'bottom',
    //             // customLegendItems: ["ambulance","homecare","Doctors"],
    //             width: '200px'
    //           },
    //           responsive: [{
    //             breakpoint: 480,
    //             options: {
    //               chart: {
    //                 width: 200,
                   
    //               },
                
    //             }
    //           }]
    //         },
          
          
    //       }
    // )
    return(
        <>
        <div style={{paddingTop:"50px",paddingBottom:"60px"}}>
        <Chart 
        series={props.data} 
        type="donut"
        // width={370}
        height={ 540}
        options={{
          labels:['Ambulance','TeleConsultation','HomeCare Service'],
          legend: {
                    position: 'bottom',
                    paddingTop:'30px',
                  },
          plotOptions : {
            pie:{
              donut:{
                labels:{
                  show:true,
                }
              }
            }
          }
        }} 
        
        
       
       
        
        />
        
        </div>
    
        
        
        </>

    );

}
export default DonutChart;