
import moment from 'moment';
import React, { useEffect, useMemo,useState } from 'react'
import { Row, Col, Card, Form, FloatingLabel, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom"
import axios from "axios";
import { toast } from 'react-toastify';
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import Pagination from '../layout/Pagination';
let PageSize = 10;

const Payout = () => {

    const location = useLocation();
    let periodfromdash = (location.state !== undefined && location.state !== null) ? location.state.period : ""

    const [Period, setPeriod] = useState("ThisYear");
    const [Service, SetService] = useState("");
    const [ToDate, setToDate] = useState("");
    const [FromDate, setFromDate] = useState("");
    const [SearchBy, setSearchBy] = useState("");


    const [PayoutData, setPayoutData] = useState([]);
    const [totalPayout, setTotalPayout] = useState("");
    const [totalPaid, settotalPaid] = useState("");
    const [totalPayoutDues, setTotalPayoutDues] = useState("");
    const [isSending, setIsSending] = useState(false);


    const [DuesData, setDuesData] = useState([]);
    const [ServiceDues, setServiceDues] = useState("");
    const [SearchByDues, setSearchByDues] = useState("");
    const [totalDues, settotalDues] = useState("");
    const [isDues, setIsDues] = useState(false);
    const [isLoaded, SetisLoaded] = useState(true)
//paging
const [currentPage, setCurrentPage] = useState(1);


    useEffect(() => {
        if (isSending) {
            setIsSending(false);

        }
        var p = Period;
        if (periodfromdash !== null && periodfromdash !== undefined) {
            console.log('satus value ', periodfromdash)
            if (periodfromdash === 'ThisYear')
                p = "ThisYear"
            if (periodfromdash === 'ThisMonth')
                p = "ThisMonth"
            if (periodfromdash === 'ThisWeek')
                p = "ThisWeek"
            if (periodfromdash === 'PreviousYear')
                p = "PreviousYear"
            if (periodfromdash === 'PreviousMonth')
                p = "PreviousMonth"
            if (periodfromdash === 'PreviousWeek')
                p = "PreviousWeek"


        }
        setPeriod(p);
        searchData(p, Service, ToDate, FromDate, SearchBy);

    }, [isSending]);

    useEffect(() => {
        if (isDues) {
            setIsDues(false);

        }

        searchDuesData(ServiceDues, SearchByDues);

    }, [isDues]);


    const searchData = async (Period, Service, ToDate, FromDate, SearchBy) => {
        SetisLoaded(false)
        var result = await APIHandler.get(`search/payouts?service=${Service}&search_key=${SearchBy}&period=${Period}&from_date=${FromDate}&to_date=${ToDate}`)
        setPayoutData(result.data.payout);

        setTotalPayout(result.data.total_payouts);
        settotalPaid(result.data.total_paid);
        setTotalPayoutDues(result.data.total_dues);
        SetisLoaded(true)
    }
    const searchDuesData = async (Service, SearchBy) => {
        SetisLoaded(false)
        var result = await APIHandler.get(`search/dues?service=${ServiceDues}&search_key=${SearchByDues}`)
        setDuesData(result.data.payout);
        settotalDues(result.data.total_dues)
        SetisLoaded(true)
    }

    const currentPayoutData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return PayoutData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage,PayoutData]);

    const currentDuesData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return DuesData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage,DuesData]);


    return (<>
        <Layout />

        <div className="content-wrapper my_wrapper">
            <div className="content-header">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Payouts</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active">Payouts</li>
                        </ol>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="row mt-2">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header d-flex p-0">
                                <ul className="nav nav-pills p-2">
                                    <li className="nav-item"><a className="nav-link active" href="#tab_1" data-toggle="tab">Payouts </a></li>
                                    <li className="nav-item"><a className="nav-link" href="#tab_2" data-toggle="tab">Dues</a></li>
                                </ul>
                            </div>
                            <div className="card-body">
                                <div className="tab-content">
                                    <div className="tab-pane active" id="tab_1">
                                        <div className="row">
                                            <div className="col-lg-2">
                                                <label>Service</label>
                                                <select className="form-control" name="Service" value={Service} onChange={(e) => SetService(e.target.value)}>
                                                    <option value="">All Services</option>
                                                    <option value="Doctor">Teleconsultation</option>
                                                    <option value="Nurse">Home Care Nurse</option>
                                                    <option value="Ambulance">Ambulances Providers</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-2">
                                                <label>Period</label>
                                                <select className="form-control" name="Period" value={Period} onChange={(e) => setPeriod(e.target.value)}>
                                                    <option value="">All</option>
                                                    <option value="ThisWeek">This Week</option>
                                                    <option value="ThisMonth">This Month</option>
                                                    <option value="ThisYear">This Year</option>
                                                    <option value="PreviousWeek">Last Week</option>
                                                    <option value="PreviousMonth">Last Month</option>
                                                    <option value="PreviousYear">Last Year</option>
                                                    <option value="Custom">Custom</option>
                                                </select>
                                            </div>
                                            {Period === "Custom" &&
                                                <>
                                                    <div className="col-lg-2">
                                                        <label>From Date</label>
                                                        <input type="date" className="form-control" placeholder="From Date" value={FromDate} onChange={(e) => setFromDate(e.target.value)} />
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <label>To Date</label>
                                                        <input type="date" className="form-control" placeholder="To Date" value={ToDate} onChange={(e) => setToDate(e.target.value)} />
                                                    </div>

                                                </>
                                            }


                                            <div className="col-lg-2">
                                                <label>Search</label>
                                                <input type="text" className="form-control" placeholder="Search" value={SearchBy} onChange={(e) => setSearchBy(e.target.value)} />
                                            </div>
                                            <div className="col-lg-2">
                                                <label >Action</label>
                                                <div>
                                                    <button className="btn btn-primary" onClick={(e) => setIsSending(true)} ><i className="fa fa-search"></i> Search </button>
                                                    <button className="btn btn-primary ml-3"> <i className="fa fa-print"></i> </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-lg-12">
                                                <div className="card_header">
                                                    <h3>Total Payout : <b>Rs. {totalPayout}</b></h3>
                                                    <h3>Total Paid : <b>Rs. {totalPaid}</b></h3>
                                                    <h3>Total Dues : <b>Rs. {totalPayoutDues}</b> </h3>
                                                </div>
                                                <table className="table table-striped  table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Service Provider</th>
                                                            <th>Service</th>
                                                            <th>Bookings#</th>
                                                            <th>FTMargin </th>
                                                            <th>Payout</th>
                                                            <th>Paid</th>
                                                            <th>Dues</th>
                                                            
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                    {isLoaded?(
                                                        (currentPayoutData!==null && currentPayoutData.length>0)?
                                                        currentPayoutData.map(obj => (
                                                            <tr>
                                                                <td>
                                                                    {obj.service_Provider}
                                                                </td>
                                                                <td>{obj.service}</td>
                                                                <td>{obj.bookings}</td>
                                                                <td>{obj.ft_margin !== null ? (Math.round(obj.ft_margin * 100) / 100).toFixed(2) : ""}</td>
                                                                <td>{obj.payouts !== null ? (Math.round(obj.payouts * 100) / 100).toFixed(2) : ""}</td>
                                                                <td>{obj.paid}</td>
                                                                <td>{obj.dues}</td>
                                                                {/* <td>View</td>
                                                                <td>Make Payment</td> */}


                                                            </tr>
                                                        )):<tr><td colSpan={7}>No data found</td></tr>):<tr><td colSpan={7}>  <div className="loader"></div></td></tr>}
                                                    </tbody>
                                                </table>
                                                <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPage}
                                                    totalCount={PayoutData.length}
                                                    pageSize={PageSize}
                                                    onPageChange={page => setCurrentPage(page)}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="tab-pane" id="tab_2">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <label>Service</label>
                                                <select className="form-control" name="Service" value={ServiceDues} onChange={(e) => setServiceDues(e.target.value)}>
                                                    <option value="">All Services</option>
                                                    <option value="Doctor">Teleconsultation</option>
                                                    <option value="Nurse">Home Care Nurse</option>
                                                    <option value="Ambulance">Ambulances Providers</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-3">
                                                <label>Search</label>
                                                <input type="text" className="form-control" placeholder="Search" value={SearchByDues} onChange={(e) => setSearchByDues(e.target.value)} />

                                            </div>
                                            <div className="col-lg-3">
                                                <label style={{ visibility: 'hidden' }}>Action</label>
                                                <div>
                                                    <button className="btn btn-primary" onClick={(e) => setIsDues(true)}><i className="fa fa-search"></i>  Search</button>

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <label style={{ visibility: 'hidden' }}>Action</label>
                                                <div>
                                                    <button className="btn btn-primary ml-3 pull-right"> <i className="fa fa-print"></i> Print</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-lg-12">
                                                <div className="card_header">
                                                    <h3>Total Dues : <b>Rs.{totalDues}</b> </h3>
                                                </div>

                                                <table className="table table-striped  table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Service Provider</th>
                                                            <th>Service</th>
                                                            <th>Bookings#</th>
                                                            <th>FTMargin </th>
                                                            <th>Payout</th>
                                                            <th>Paid</th>
                                                            <th>Dues</th>
                                                            {/* <th>Ledger</th>
                                                            <th>Action</th> */}
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                    {isLoaded?
                                                       ( (currentDuesData!==null && currentDuesData.length>0)?
                                                        currentDuesData.map(obj => (
                                                            <tr>
                                                                <td> 
                                                                    {obj.service_Provider}
                                                                </td>
                                                                <td>{obj.service}</td>
                                                                <td>{obj.bookings}</td>
                                                                <td>{obj.ft_margin !== null ? (Math.round(obj.ft_margin * 100) / 100).toFixed(2) : ""}</td>
                                                                <td>{obj.payouts !== null ? (Math.round(obj.payouts * 100) / 100).toFixed(2) : ""}</td>
                                                                <td>{obj.paid}</td>
                                                                <td>{obj.dues}</td>
                                                                {/* <td>View</td>
                                                                <td>Make Payment</td> */}


                                                            </tr>
                                                        )):<tr><td colSpan={7}>No data found</td></tr>):<tr><td colSpan={7}>  <div className="loader"></div></td></tr>}
                                                    </tbody>
                                                </table>
                                                <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPage}
                                                    totalCount={DuesData.length}
                                                    pageSize={PageSize}
                                                    onPageChange={page => setCurrentPage(page)}
                                                />
                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>

                    </div>

                </div>




            </section>
        </div>




    </>);
}
export default Payout;