import Header from "../layout/header";
import Footer from "../layout/footer";
import Sidebar from "../layout/sidebar";
import axios from 'axios';
import { useState, useEffect } from 'react';
import Layout from "../layout/layout";
import Ploatchart from "../../components/ploatchart";
import DonutChart from "../../components/Donutchart";
import APIHandler from "../../helper/APIHandler";
// import { toast } from 'react-toastify';
import { Row, Col, Card } from 'react-bootstrap';
import { Donut, Legend, ResponsiveContainer } from 'britecharts-react';
import Chart from 'react-apexcharts';
import moment from 'moment';
import { Link } from "react-router-dom";
import { array, number } from "prop-types";







const Dashboard = () => {

    const [fortoday, setfortoday] = useState([])
    const [overall, setoverall] = useState([])
    const [pending, setpending] = useState([])
    const [user, setuser] = useState([])
    const [other, setother] = useState([])
    const [bookingBreakup, setbookingBreakup] = useState("ThisYear")
    const [receiveddata, setreceived] = useState([])
    const [completeddata, setcompleted] = useState([])
    const [revenuBreakup, setrevenuBreakup] = useState("ThisYear")
    const [bookingvs, setbookingvs] = useState("ThisYear")
    const [revenuvs, setrevenuvs] = useState("ThisYear")
    const [dates, setdates] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [dashboardResponse, setDashboardResponse] = useState([]);
    const [bookingbreakupdata, setbookingbreakupdata] = useState([]);
    const [revenubreakupdata, setrevenubreakupdata] = useState([])
    const [bookingvsdata, setbookingvsupdata] = useState([])
    const [revenuvsupdata, setrevenuvsupdata] = useState([])
    const [summarydata, setsummarydata] = useState([])
    const [carddate, setcarddate] = useState([])
    const [status, setstatus] = useState("true")
    const [overallsummary, setoverallsummary] = useState("ThisYear")
    const [overallsummarydata, setoverallsummarydata] = useState([])
    const [revenus,setrevenus] = useState([])
    const [payouts,setpayouts] = useState([])






    const data1 = [bookingbreakupdata.ambulance_percentage, bookingbreakupdata.doctor_percentage, bookingbreakupdata.home_care_percentage];
    const data2 = [revenubreakupdata.ambulance_percentage, revenubreakupdata.doctor_percentage, revenubreakupdata.home_care_percentage];
    const [series1, setSeries1] = useState([]);

    const bookingvscomplete = async () => {
        const result = await APIHandler.get(`dashboard/admin/bookingvscompleted?period=${bookingvs}`);

        setbookingvsupdata(result.data)
        var recieved=[];
        var completed=[];
        const bookings = result.data;
        //result.data.map((key) => {
            for(let i=0; i <12; i++){
                const foundBooking = bookings.find(k=> new Date(k.date).getMonth() === i);
                console.log(foundBooking, "foundbooking");
                if(!foundBooking){
                    recieved.push(0);
                    completed.push(0);
                }
                else {
                    recieved.push(foundBooking.received);
                    completed.push(foundBooking.completed);
                }
            }
        setreceived(recieved)
        setcompleted(completed)
    }


    const revenuvscomplete = async () => {
        const result = await APIHandler.get(`dashboard/admin/revenuevspayout?period=${revenuvs}`);
        setrevenuvsupdata(result.data)
        var revenu=[];
        var payout=[];
        const bookings = result.data;
        //result.data.map((key) => {
            for(let i=0; i <12; i++){
                const foundBooking = bookings.find(k=> new Date(k.date).getMonth() === i);
                console.log("Booking", foundBooking);
                if(!foundBooking || foundBooking === undefined){
                    revenu.push(0);
                    payout.push(0);
                }
                else {
                    revenu.push(foundBooking.revenue);
                    payout.push(foundBooking.payout);
                }
            }
        setrevenus(revenu)
        setpayouts(payout)
    }
    const bookingbreakup = async () => {
        const result = await APIHandler.get(`dashboard/admin/bookingbreakup?period=${bookingBreakup}`);
        setbookingbreakupdata(result.data)
    }
    const revenubreakup = async () => {
        const result = await APIHandler.get(`dashboard/admin/revenuebreakup?period=${revenuBreakup}`);
        setrevenubreakupdata(result.data)
    }
    const newbooking = async () => {
        const result = await APIHandler.get(`dashboard/admin/newbookings?date=${dates}`);
        setcarddate(result.data)
    }
    const overallsummery = async () => {
        const result = await APIHandler.get(`dashboard/admin/overallsummary?period=${overallsummary}`);
        setoverallsummarydata(result.data)
    }
    const usersummary = async () => {
        const result = await APIHandler.get(`dashboard/admin/usersummary?active=${status}`);
        setsummarydata(result.data)
    }



    const getcarddata = async () => {

        try {
            const result = await APIHandler.get('dashboard/admin?period=ThisYear');



            setfortoday(result.data.scheduled_bookings)
            setoverall(result.data.overall_summary)
            setpending(result.data.pending_activities)
            setuser(result.data.user_summary)
            setother(result.data.other_summary)
            // var recieved=[0,0,0,0,0,0,0,0,0,0,0,0];
            // var completed=[0,0,0,0,0,0,0,0,0,0,0,0];
            // result.data.booking_received_completed.map(key=>

            //    {

            //      for(var i=1;i<=12;i++){
            //     if((key.date.getMonth()) ==i){
            //         var index=i-1
            //         console.log(key.date.getMonth())
            //         recieved[index]= Number(key.received)
            //         completed[index]=Number(key.completed)
            //         }
            //     }});

            //     setreceived(recieved)
            //     setcompleted(completed)



        } catch (err) {
            console.error(err.message)
        }
    }
    useEffect(() => {
        getcarddata();
    }, [ ])
    useEffect(()=>{ bookingvscomplete();},[ bookingvs])
    useEffect(()=>{  revenuvscomplete();},[revenuvs])
    useEffect(()=>{bookingbreakup();},[bookingBreakup])
    useEffect(()=>{ revenubreakup();},[ revenuBreakup])
    useEffect(()=>{ newbooking();},[dates])
    useEffect(()=>{ usersummary();},[status])
    useEffect(()=>{overallsummery();},[overallsummary])

    useEffect(() => {
        console.log("data:" + receiveddata);
        
        setSeries1([{
            name: 'Bookings Received',
            data: (receiveddata.length>0)?receiveddata:[0,0,0,0,0,0,0,0,0,0,0,0]
        }, {
            name: 'Booking Completed',
            data: (completeddata.length>0)?completeddata:[0,0,0,0,0,0,0,0,0,0,0,0]
        }])
       
    }, [receiveddata, completeddata])
    console.log(receiveddata, "my receive data");


    const series2 = [{
        name: 'Revenue',
        data: (revenus.length>0)?revenus:[0,0,0,0,0,0,0,0,0,0,0,0]
    }, {
        name: 'Payouts',
        data: (payouts.length>0)?payouts:[0,0,0,0,0,0,0,0,0,0,0,0]
    }];
    console.log(revenus,"sdfds")
    console.log(payouts,"sdfdsdfgnfdgj")

    return (
        <>
            <div className="wrapper">
                <Layout />
                {/* <!-- Content Wrapper. Contains page content --> */}
                <div className="content-wrapper">
                    {/* <!-- Content Header (Page header) --> */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Dashboard</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Dashboard</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Main content --> */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-6">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h3 className="card-title">New Bookings</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{ width: "150px" }}>
                                                    <input type="date" name="table_search" className="form-control" value={dates} onChange={(e) => setdates(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">                                       
                                           {(carddate !== null && carddate.received >= 0) ?                                          
                                            <div className="row">
                                                <div className="col-lg-3">
                                                <Link to="/bookings" state={{period: dates,status:"Received"}}>
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Received</p>
                                                                <h3 className="mb-0 text-primary"> {(carddate.received>1000)?carddate.received/1000+"K":carddate.received}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                                <div className="col-lg-3">
                                                <Link to="/bookings" state={{period: dates,status:"Confirmed"}}>
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Confirmed</p>
                                                                <h3 className="mb-0 text-primary"> {(carddate.confirmed>1000)?carddate.confirmed/1000+"K":carddate.confirmed}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-3">
                                                <Link to="/bookings" state={{period: dates,status:"NotConfirmed"}}>
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Not Confirmed</p>
                                                                <h3 className="mb-0 text-primary"> {(carddate.not_confirmed>1000)?carddate.not_confirmed/1000+"K":carddate.not_confirmed}</h3>
                                                            </div>

                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-3">
                                                <Link to="/bookings" state={{period: dates,status:"Rejected"}}>
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Rejected</p>
                                                                <h3 className="mb-0 text-primary"> {(carddate.rejected>1000)?carddate.rejected/1000+"K":carddate.rejected}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            :<div className="loader"></div>
                                           }
                                        </div>
                                    </div>

                                </div>
                                <div className="col-6">
                                    <div className="card card card-success">
                                        <div className="card-header">
                                            <h3 className="card-title">Scheduled For Today</h3>
                                        </div>
                                        <div className="card-body">
                                        {(fortoday !== null && fortoday.scheduled >= 0) ?   
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <Link to={"#"}>
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center ">
                                                                <p>Scheduled</p>
                                                                <h3 className="mb-0 text-success">{fortoday.scheduled}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-3">
                                                    <Link to={"#"}>
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Completed</p>
                                                                <h3 className="mb-0 text-success">{fortoday.completed}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-3">
                                                    <Link to={"#"}>
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Pending</p>
                                                                <h3 className="mb-0 text-success">{fortoday.pending}</h3>
                                                            </div>

                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-3">
                                                    <Link to={"#"}>
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Cancelled</p>
                                                                <h3 className="mb-0 text-success">{fortoday.cancelled}</h3>
                                                            </div>

                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            :<div className="loader"></div>
                                        } 
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="card card-purple">
                                        <div className="card-header">
                                            <h3 className="card-title">Overall Summary</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{ width: "150px" }}>
                                                    <select className="form-control" onChange={(e) => setoverallsummary(e.target.value)}>
                                                        <option value={"ThisYear"}>This Year</option>
                                                        <option value={"ThisMonth"}>This Month</option>
                                                        <option value={"ThisWeek"}>This Week</option>
                                                        <option value={"PreviousYear"}>Previous Year</option>
                                                        <option value={"PreviousMonth"}>Previous Month</option>
                                                        <option value={"PreviousWeek"}>Previous Week</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body ">
                                        {(overallsummarydata !== null && overallsummarydata.bookings >= 0) ?  
                                            <div className="row">
                                                <div className="col-lg-3">
                                                <Link to="/bookings" state={{period: overallsummary,status:"Booking"}}>                                                   
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Bookings</p>
                                                                <h3 className="mb-0 text-purple">{overallsummarydata.bookings}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-3">
                                                <Link to="/bookings" state={{period: overallsummary,status:"Completed"}}>   
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Completed</p>
                                                                <h3 className="mb-0 text-purple">{overallsummarydata.completed}</h3>
                                                            </div>

                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-3">
                                                <Link to="/revenus" state={{period: overallsummary}}>   
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Revenue</p>
                                                                <h3 className="mb-0 text-purple">{(overallsummarydata.revenue>1000)?overallsummarydata.revenue/1000+"K":overallsummarydata.revenue}</h3>
                                                            </div>

                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-3">
                                                <Link to="/payouts" state={{period: overallsummary}}>   
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Payouts</p>
                                                                <h3 className="mb-0 text-purple">{overallsummarydata.payout}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            :<div className="loader"></div>
                                        }
                                        </div>
                                    </div>

                                </div>
                                <div className="col-6">
                                    <div className="card card-danger">
                                        <div className="card-header">
                                            <h3 className="card-title">Action Needed on Pending Activites</h3>
                                        </div>
                                        <div className="card-body ">
                                        {(pending !== null && pending.bookings >= 0) ?  
                                            <div className="row">
                                                <div className="col-lg-3">
                                                <Link to="/bookings" state={{period: overallsummary,status:"Booking"}}>  
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Bookings</p>
                                                                <h3 className="mb-0 text-danger">{pending.bookings}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-3">
                                                <Link to="/bookings" state={{period: overallsummary,status:"Booking"}}>  
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Approvals</p>
                                                                <h3 className="mb-0 text-danger">{pending.approvals}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-3">
                                                <Link to="/bookings" state={{period: overallsummary,status:"Booking"}}>  
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Query</p>
                                                                <h3 className="mb-0 text-danger">{pending.query}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-3">
                                                <Link to="/payouts" state={{period: overallsummary}}>   
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Payout</p>
                                                                <h3 className="mb-0 text-danger">{pending.payout}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            :<div className="loader"></div>
                                        }
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="card card-olive">
                                        <div className="card-header">
                                            <h3 className="card-title">User Summary</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{ width: "150px" }}>
                                                    <select className="form-control" onChange={(e) => setstatus(e.target.value)}>
                                                        <option value="true">Active</option>
                                                        <option value="false">Not Active</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body ">
                                        {(summarydata !== null && summarydata.doctors >= 0) ? 
                                            <div className="row">
                                                <div className="col-lg-3">
                                                <Link to="/users" state={{role: "Doctor",status:status}}>
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Doctors</p>
                                                                <h3 className="mb-0 text-olive">{summarydata.doctors}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-3">
                                                <Link to="/users" state={{role: "Nurse",status:status}}>
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Nurses</p>
                                                                <h3 className="mb-0 text-olive">{summarydata.nurses}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-3">
                                                <Link to="/users" state={{role: "Ambulance",status:status}}>
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Ambulances</p>
                                                                <h3 className="mb-0 text-olive">{summarydata.ambulances}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-3">
                                                <Link to="/users" state={{role: "AllPatients",status:status}}>
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Patients</p>
                                                                <h3 className="mb-0 text-olive">{summarydata.patients}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            :<div className="loader"></div>
                                        }

                                        </div>
                                    </div>

                                </div>
                                <div className="col-6">
                                    <div className="card card-teal">
                                        <div className="card-header">
                                            <h3 className="card-title">Other Summary</h3>
                                        </div>
                                        <div className="card-body ">
                                        {(other !== null && other.specializations >= 0) ? 
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <Link to={"#"}>
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Specialization</p>
                                                                <h3 className="mb-0 text-teal">{other.specializations}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-3">
                                                    <Link to={"#"}>
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>HC Services</p>
                                                                <h3 className="mb-0 text-teal">{other.homecares}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-3">
                                                    <Link to={"#"}>
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Loactions</p>
                                                                <h3 className="mb-0 text-teal">{other.locations}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-3">
                                                    <Link to={"#"}>
                                                        <div className="small-box mb-0 card-text">
                                                            <div className="inner text-center">
                                                                <p>Amb. Type</p>
                                                                <h3 className="mb-0 text-teal">{other.ambulance_type}</h3>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            :<div className="loader"></div>
                                        }
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    <div className="card card-olive">
                                        <div className="card-header">
                                            <h3 className="card-title">Bookings Received v/s Booking Completed</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{ width: "150px" }}>
                                                    <select className="form-control" onChange={(e) => setbookingvs(e.target.value)}>
                                                    <option value={"ThisYear"}>This Year</option>
                                                        <option value={"ThisMonth"}>This Month</option>
                                                        <option value={"ThisWeek"}>This Week</option>
                                                        <option value={"PreviousYear"}>Previous Year</option>
                                                        <option value={"PreviousMonth"}>Previous Month</option>
                                                        <option value={"PreviousWeek"}>Previous Week</option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body ">
                                            {series1.length > 0 ?
                                                <>
                                                    <Ploatchart series={series1} />
                                                </>
                                                : <div className="center">Loading.....</div>}


                                        </div>
                                    </div>

                                </div>
                                <div className="col-4">
                                    <div className="card card-teal">
                                        <div className="card-header">
                                            <h3 className="card-title">Bookings Breakup</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{ width: "150px" }}>
                                                    <select className="form-control" onChange={(e) => setbookingBreakup(e.target.value)}>
                                                    <option value={"ThisYear"}>This Year</option>
                                                        <option value={"ThisMonth"}>This Month</option>
                                                        <option value={"ThisWeek"}>This Week</option>
                                                        <option value={"PreviousYear"}>Previous Year</option>
                                                        <option value={"PreviousMonth"}>Previous Month</option>
                                                        <option value={"PreviousWeek"}>Previous Week</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        < DonutChart data={data1} />
                                    </div>

                                </div>
                                <div className="col-8">
                                    <div className="card card-olive">
                                        <div className="card-header">
                                            <h3 className="card-title">Revenue v/s Payouts</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{ width: "150px" }}>
                                                    <select className="form-control" onChange={(e) => revenuvs(e.target.value)}>
                                                    <option value={"ThisYear"}>This Year</option>
                                                        <option value={"ThisMonth"}>This Month</option>
                                                        <option value={"ThisWeek"}>This Week</option>
                                                        <option value={"PreviousYear"}>Previous Year</option>
                                                        <option value={"PreviousMonth"}>Previous Month</option>
                                                        <option value={"PreviousWeek"}>Previous Week</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body ">
                                            <Ploatchart series={series2} />
                                        </div>
                                    </div>

                                </div>
                                <div className="col-4">
                                    <div className="card card-teal">
                                        <div className="card-header">
                                            <h3 className="card-title">Revenue Breakup</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{ width: "150px" }}>
                                                    <select className="form-control" onChange={(e) => setrevenuBreakup(e.target.value)}>
                                                    <option value={"ThisYear"}>This Year</option>
                                                        <option value={"ThisMonth"}>This Month</option>
                                                        <option value={"ThisWeek"}>This Week</option>
                                                        <option value={"PreviousYear"}>Previous Year</option>
                                                        <option value={"PreviousMonth"}>Previous Month</option>
                                                        <option value={"PreviousWeek"}>Previous Week</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body ">
                                            <div className="row">
                                                <div className="col-lg-12">

                                                    < DonutChart data={data2} />

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                </div>

                {/* <!-- /.content-wrapper --> */}

            </div>

            <Footer />


        </>


    );
}
export default Dashboard;