import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, FloatingLabel, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom"
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import { toast } from 'react-toastify';
import moment from 'moment';

const UserProfile = () => {

    const location = useLocation()
    const userid = location.state?.userid
    const [tab, setTab] = useState('profile details')

    const [btnApprove, setTextApprove] = useState('Approve')
    const [btnReject, setTextReject] = useState('Reject')
    const [statusDate, setstatusDate] = useState('')
    const [isDoctor, setisDoctor] = useState("")
    const [isCallDoctor, setCallDoctor] = useState(null)



    const [userForm, setUserForm] = useState(
        {
            id: userid || 0,
            name: "",
            mobile: "",
            email: "",
            gender: "",
            state: "",
            city: "",
            zipcode: "",
            address: "",
            history: "",
            notes: "",
            complete_address:"",
            medical_history:""
        }
    )


    const { id, name, mobile, email, gender, state, city, zipcode,complete_address,notes,medical_history } = userForm;
    const [teleconsultationData, setTeleconsultationData] = useState({

        headline: '',
        specialization: [],
        city: "",
        bio: '',
        address: "",
        availability: [],
        consultation_fees: "",
        degree: '',
        followup_fees: "",
        service_provider_document: [],
        state: "",
        zipcode: '',
        gender: '',
        experience: '',
        spelization_id: '',
        profile_pic: '',
        applied_on: '',
        approved_date: '',
        signature:''
        
    })


    const { headline, bio,
        degree, followup_fees,
        specialization, availability, service_provider_document,
        consultation_fees,
        experience, spelization_id, profile_pic, applied_on, approved_date,signature,address  } = teleconsultationData;


    useEffect(() => {
        console.log(userid)
        searchData(id)
        console.log(userid)
        ConsultationData(id)

        setCallDoctor(false)
        console.log("dr call ")
      
    }, [])

  

    const searchData = async (id) => {
        var result = await APIHandler.get(`users/${id}`) 
        //var result = await APIHandler.get(`doctors/${id}/profile`)
        console.log("Rakesh User Call ",result.data)
        setUserForm(result.data);

    }

    // Teleconsultation


    const ConsultationData = async (id) => {

        try{
            var result = await APIHandler.get(`doctors/${id}/profile`)
        
       
          
            console.log("Rakesh dr call",result.data)
           if(result.data!==null && result.data!==undefined)
           {
            console.log("Rakesh dr call in function ",result.data)
            setTeleconsultationData(result.data);
            setisDoctor("Yes")
            if (result.data.is_approved === 1) {
                setTextApprove("Activate");
                setTextReject("Block");
                setstatusDate(" Approved On : " + moment(result.data.approved_date).format("YYYY/MM/DD"));
                console.log(statusDate);
            }
            else {
                setTextApprove("Approve");
                setTextReject("Reject");
                setstatusDate(" Applied On : " + moment(result.data.applied_on).format("YYYY/MM/DD"));
            }
           }
           else{
            setisDoctor("No")
           }
        }
        catch(error)
        {

        }
         
       


    }
    //update status
    const UpdateUserStatus = async (e, status) => {

        var result = await APIHandler.patch(`/users/${id}/services/update?service=Doctor&changeFor=Approval&status=${status}`)
        console.log(result, "Approve")
        if (result != undefined) {
            if (result.success) {
                if (result.data.is_approved) {
                    toast.success("User successfully approved")
                }
                else {
                    toast.success("User successfully rejected")
                }
            }
        }
        ConsultationData(id)

    }

    return (
        <>
            <Layout />

            <div className="content-wrapper my_wrapper">
                <div className="content-header">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{name}</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <Link to="/users"><i className="bi bi-chevron-compact-left"></i> Back to user</Link>
                            </ol>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="row mt-2">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header d-flex p-0">
                                    <ul className="nav nav-pills p-2">
                                        <li className="nav-item"><a className="nav-link active" href="#tab_1" data-toggle="tab">Personal Details </a></li>
                                        <li className="nav-item"><a className="nav-link" href="#tab_2" data-toggle="tab" onClick= {() => setCallDoctor(true)}>Teleconsultation</a></li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="tab_1">


                                            <div className="row mb-3">
                                                <div className='col-lg-9'>
                                                    <div className="row">
                                                        <div className='col-lg-4'>
                                                            <Form.Group as={Col} controlId="formGridCity" className='mb-3'>
                                                                <Form.Label>Name</Form.Label>
                                                                <Form.Control name="name" value={name} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-lg-4'>
                                                            <Form.Group as={Col} controlId="formGridState" className='mb-3'>
                                                                <Form.Label>Phone </Form.Label>
                                                                <Form.Control name="mobile" value={mobile} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-lg-4'>
                                                            <Form.Group as={Col} controlId="formGridCity" className='mb-3'>
                                                                <Form.Label>Email </Form.Label>
                                                                <Form.Control name="email" value={email}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-lg-4'>
                                                            <Form.Group as={Col} controlId="formGridState" className='mb-3'>
                                                                <Form.Label>Gender</Form.Label>
                                                                <Form.Control name="gender" value={gender} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-lg-4'>
                                                            <Form.Group as={Col} controlId="formGridCity" className='mb-3'>
                                                                <Form.Label>State</Form.Label>
                                                                <Form.Control name="state" value={state} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-lg-4'>
                                                            <Form.Group as={Col} controlId="formGridState" className='mb-3'>
                                                                <Form.Label>City</Form.Label>
                                                                <Form.Control name="city" value={city} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-lg-4'>
                                                            <Form.Group as={Col} controlId="formGridState" className='mb-3'>
                                                                <Form.Label>Zip Code</Form.Label>
                                                                <Form.Control name="zipcode" value={zipcode} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-lg-8'>
                                                            <Form.Group as={Col} controlId="formGridCity" className='mb-3'>
                                                                <Form.Label>Complete Address</Form.Label>
                                                                <Form.Control name="address" value={complete_address} />
                                                            </Form.Group>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="tele-profile-box mt-4 ">
                                                        <div className="img-profile"><img src={(profile_pic !== null && profile_pic !== "null") ? profile_pic : "dist/img/avatar.png"} /></div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className='col-lg-12'>
                                                    <Form.Group as={Col} controlId="formGridCity" className='mb-3'>
                                                        <Form.Label>Medical History(if any)</Form.Label>
                                                        <Form.Control as="textarea" rows={2} name="history" value={medical_history} />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-lg-12'>
                                                    <Form.Group as={Col} controlId="formGridCity" className='mb-3'>
                                                        <Form.Label>Note (if any)</Form.Label>
                                                        <Form.Control as="textarea" rows={2} name="note" value={notes} />
                                                    </Form.Group>
                                                </div>
                                            </div>



                                        </div>
                                        
                                       
                                        <div className="tab-pane" id="tab_2">
                                        {
                                            isDoctor==="Yes" || isDoctor==="yes"?
                                           <>
                                          
                                           <div className='user-action-box'>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <Form.Group as={Col} controlId="tele">
                                                            <Form.Label>{statusDate}</Form.Label>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-6 text-right">
                                                        <Form.Group as={Col} controlId="tele">
                                                            <input type="Submit" name="btnApprove" value={btnApprove} className="btn btn-success" onClick={e => UpdateUserStatus(e.target.value, true)} />
                                                            <input type="Submit" name="btnReject" value={btnReject} className="btn btn-danger ml-3" onClick={e => UpdateUserStatus(e.target.value, false)} />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row ">
                                                <div className="col-lg-9">
                                                    <Form.Group as={Col} controlId="tele" className='mb-3' >
                                                        <Form.Label>Headline</Form.Label>
                                                        <Form.Control name="Headline" value={headline} />

                                                    </Form.Group>


                                                    <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                        <Form.Label>Brief Details</Form.Label>
                                                        <Form.Control as="textarea" rows={2} name="details" value={bio} />
                                                    </Form.Group>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="tele-profile-box mt-4">
                                                        <div className="img_sign"><img src={(signature !== null && signature !== "null") ? signature : "dist/img/vinayak-sign.png"} /></div>
                                                    </div>
                                                </div>

                                            </div>


                                            <div className="row mt-4">
                                                <div className="col-lg-3">
                                                    <Form.Group as={Col} controlId="tele"  className='mb-3'>
                                                        <Form.Label>Total Experiance (in years)</Form.Label>
                                                        <Form.Control name="experiance" value={experience} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-3">
                                                    <Form.Group as={Col} controlId="tele"  className='mb-3'>
                                                        <Form.Label>Degree</Form.Label>
                                                        <Form.Control name="degree" value={degree} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-3">
                                                    <Form.Group as={Col} controlId="tele"  className='mb-3'>
                                                        <Form.Label>Consultation Fee</Form.Label>
                                                        <Form.Control name="consultationfee" value={consultation_fees} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-3">
                                                    <Form.Group as={Col} controlId="tele"  className='mb-3'>
                                                        <Form.Label>Followup Fee</Form.Label>
                                                        <Form.Control name="followupfee" value={followup_fees} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group as={Col} controlId="tele"  className='mb-3'>
                                                        <Form.Label>Specialization</Form.Label>
                                                        <table className="table table-striped  table-bordered " >
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        Specialization
                                                                    </th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {specialization.map(obj => (
                                                                    <tr>
                                                                        <td>{obj.type}</td>

                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group as={Col} controlId="tele"  className='mb-3'>
                                                        <Form.Label>AddAvibility</Form.Label>
                                                        <div className="">
                                                            <table className="table table-striped  table-bordered" >
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            Days
                                                                        </th>
                                                                        <th>
                                                                            From Time
                                                                        </th>
                                                                        <th>
                                                                            To Time
                                                                        </th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {availability.map(obj => (
                                                                        <tr>
                                                                            <td>{obj.day == 0 ? 'Sunday' : obj.day == 1 ? 'Monday' : obj.day == 2 ? 'Tuesday' : obj.day == 3 ? 'Wednesday' : obj.day == 4 ? 'Thursday' : obj.day == 5 ? 'Friday' : obj.day == 6 ? 'Saturday' : ''}</td>
                                                                            <td>{obj.start_time}</td>
                                                                            <td>{obj.end_time}</td>

                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-12">
                                                    <Form.Group as={Col} controlId="tele"  className='mb-3'>
                                                        <Form.Label>Documents</Form.Label>

                                                        <div className="">
                                                            <table className="table table-striped  table-bordered" >
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            Document Type
                                                                        </th>
                                                                        <th>
                                                                            Title
                                                                        </th>
                                                                        <th>
                                                                            Link
                                                                        </th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {service_provider_document.map(obj => (
                                                                        <tr>
                                                                            <td>{obj.document_type}</td>
                                                                            <td>{obj.title}</td>
                                                                            <td><a href={obj.doc_file_path} target="_blank"> Link</a> </td>

                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Form.Group>
                                                </div>


                                            </div>
                                            </>:"Not a Doctor" 
                                        
                                        }

                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>



                </section>






            </div>
        </>
    )
}
export default UserProfile;
