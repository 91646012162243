import { BrowserRouter,HashRouter,Route,Routes } from 'react-router-dom';
import Dashboard from '../pages/Dashboard/Dashboard';
import Bookings from '../pages/Bookings/Bookings';
import Payout from '../pages/Payouts/Payout';
import Users from '../pages/Users/users';
import Revenus from '../pages/Revenus/Revenus';
import Reports from '../pages/Reports/Reports';
import Layout from '../pages/layout/layout';
import UserProfile from '../pages/Users/UserProfile';
import BookingDetails from '../pages/Bookings/BookingDetails';
import Login from '../pages/Login';
import Masters from '../pages/Dashboard/Masters';

const Alltheroutes=()=>{
    return(<>
     <HashRouter>
       {/* main component routes */}
        <Routes>
        <Route path="/" element={<Login />} />       
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="bookings" element={<Bookings />} />
        <Route path="payouts" element={<Payout />} />
        <Route path="users" element={<Users />} />
        <Route path="profile" element={<UserProfile />} />       
        <Route path="revenus" element={<Revenus />} />
        <Route path="reports" element={<Reports />} />
        <Route path="booking-detail" element={<BookingDetails/>}/>
        <Route path="masters" element={<Masters/>}/>

        </Routes>
     
            
        </HashRouter>
    
    
    </>);

}
export default Alltheroutes