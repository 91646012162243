import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';

const Sidebar = () => {
    const location = useLocation()
    const pathName = location.pathname

    return (

        <>
            {/* <!-- Main Sidebar Container --> */}
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                {/* <!-- Brand Logo --> */}
                <a href="index3.html" className="brand-link">
                    <img src="dist/img/logo-white.png" alt="FastTrack Logo" className="brand-image img-circle " />
                    <span className="brand-text font-weight-medium">FastTrack</span>
                </a>

                {/* <!-- Sidebar --> */}
                <div className="sidebar">
                    {/* // <!-- Sidebar Menu --> */}
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">


                            <li className={pathName === "/dashboard" ? "nav-item active" : "nav-item"}>

                                <Link to="/dashboard" className="nav-link ">

                                    <i className="nav-icon far fa-image"></i>
                                    <p>
                                        Dashboard
                                    </p>
                                </Link>
                            </li>
                            <li className={pathName === "/masters" ? "nav-item active" : "nav-item"}>

                                <Link to="/masters" className="nav-link ">

                                    <i className="nav-icon far fa-image"></i>
                                    <p>
                                        Masters
                                    </p>
                                </Link>
                            </li>
                            <li className={pathName === "/users" ? "nav-item active" : "nav-item"}>
                                <Link to="/users" className="nav-link">
                                    <i className="nav-icon far fa-image"></i>
                                    <p>
                                        Users
                                    </p>
                                </Link>
                            </li>
                            <li className={pathName === "/bookings" ? "nav-item active" : "nav-item"}>
                                <Link to="/bookings" className="nav-link">
                                    <i className="nav-icon far fa-image"></i>
                                    <p>
                                        Bookings
                                    </p>
                                </Link>
                            </li>
                            <li className={pathName === "/revenus" ? "nav-item active" : "nav-item"}>
                                <Link to="/revenus" className="nav-link">
                                    <i className="nav-icon far fa-image"></i>
                                    <p>
                                        Revenue
                                    </p>
                                </Link>
                            </li>
                            <li className={pathName === "/payouts" ? "nav-item active" : "nav-item"}>
                                <Link to="/payouts" className="nav-link">
                                    <i className="nav-icon far fa-image"></i>
                                    <p>
                                        Payouts
                                    </p>
                                </Link>
                            </li>
                            <li className={pathName === "/reports" ? "nav-item active" : "nav-item"}>
                                <Link to="/reports" className="nav-link">
                                    <i className="nav-icon far fa-image"></i>
                                    <p>
                                        Reports
                                    </p>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    {/* <!-- /.sidebar-menu --> */}
                </div>
                {/* <!-- /.sidebar --> */}
            </aside>




        </>);
}
export default Sidebar;
